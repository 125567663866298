import { createRoot } from "react-dom/client";
import React, { useEffect, useState } from "react";
import MyMonacoEditor from "../../components/monacoEditor/monacoEditor";
import GlobalStore from "../../configuration/globalStore";
import { COMPOSER_SERVER } from '../../configuration/configuration';


export const showEditorPopup = async (language, id, filename) => {
  try {

    const retrievedData = GlobalStore.getData(); // Get JSON
    const foldername = retrievedData.title;
    
    const response = await fetch(COMPOSER_SERVER+`compile/check?foldername=${foldername}&filename=${filename}`);
    if (response.status != 200) {
        alert("File is not found. Please build the composition.");
        return;
    }

    const fileContent = await response.text(); // Read file content

    // If file exists, open the editor with the content
    openEditorPopup(language, id, foldername, filename, fileContent);
  } catch (error) {
    console.error("Error retrieving file:", error);
    alert("Error retrieving file. Please try again.");
  }
};

const openEditorPopup = (language, id, foldername, filename, initialContent) => {
  const editorDiv = document.createElement("div");
  editorDiv.style.position = "fixed";
  editorDiv.style.top = "0";
  editorDiv.style.left = "0";
  editorDiv.style.width = "100vw";
  editorDiv.style.height = "100vh";
  editorDiv.style.background = "rgba(0, 0, 0, 0.8)";
  editorDiv.style.display = "flex";
  editorDiv.style.justifyContent = "center";
  editorDiv.style.alignItems = "center";
  editorDiv.style.zIndex = "1000";
  document.body.appendChild(editorDiv);

  const root = createRoot(editorDiv);

  const closeEditor = () => {
    if (editorDiv && document.body.contains(editorDiv)) {
      root.unmount();
      document.body.removeChild(editorDiv);
    }
  };



  const EditorModal = () => {
    const [content, setContent] = useState(initialContent);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          closeEditor();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <div
        style={{
          position: "relative",
          width: "90%",
          maxWidth: "1000px",
          height: "85vh",
          background: "#1e1e1e",
          borderRadius: "12px",
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.4)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={closeEditor}
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            background: "#d9534f",
            color: "white",
            border: "none",
            padding: "8px",
            cursor: "pointer",
            borderRadius: "50%",
            fontSize: "18px",
          }}
        >
          ✕
        </button>

        <MyMonacoEditor filename={filename} foldername={foldername} id={id} language={language} intitialValue={content} onChange={setContent} />


      </div>
    );
  };

  root.render(<EditorModal />);
};
