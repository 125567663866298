import { ENDPOINTS } from '../../configuration/configuration';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from "react-icons/fa";
import React from "react";
import { createRoot } from "react-dom/client";

// Function to create an overlay element dynamically
function createOverlay(id = "overlay-container") {
    let overlay = document.getElementById(id);
    if (!overlay) {
        overlay = document.createElement("div");
        overlay.id = id;
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100vw";
        overlay.style.height = "100vh";
        overlay.style.background = "rgba(0, 0, 0, 0.6)";
        overlay.style.display = "flex";
        overlay.style.justifyContent = "center";
        overlay.style.alignItems = "center";
        overlay.style.zIndex = "1000";
        document.body.appendChild(overlay);
    }
    return overlay;
}

// Function to render React components in the overlay
function renderOverlay(content, id = "overlay-container", autoRemove = false, duration = 2000) {
    const overlay = createOverlay(id);
    const root = createRoot(overlay);
    root.render(content);

    if (autoRemove) {
        setTimeout(() => {
            root.unmount();
            document.body.removeChild(overlay);
        }, duration);
    }
}

// Function to show loading animation
function showLoading() {
    renderOverlay(
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            animation: "fadeIn 0.5s ease-in-out",
        }}>
            <FaSpinner style={{
                fontSize: "60px",
                color: "white",
                animation: "spin-animation 1s linear infinite"
            }} />
            <p style={{
                marginTop: "10px",
                fontSize: "18px",
                color: "#fff",
                fontWeight: "bold",
                textShadow: "0 0 5px rgba(255, 255, 255, 0.6)",
                animation: "textFade 1.5s ease-in-out infinite alternate"
            }}>
                Saving data...
            </p>
        </div>,
        "loading-overlay"
    );
}


// Function to remove the loading overlay
function removeLoading() {
    const overlay = document.getElementById("loading-overlay");
    if (overlay) {
        overlay.remove();
    }
}

// Function to show a success or failure icon
function showStatusIcon(status) {
    renderOverlay(
        <div style={{
            animation: "fadeIn 0.5s ease-in-out, bounce 0.6s ease-out",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            {status === "success" ? (
                <>
                    <FaCheckCircle style={{
                        fontSize: "80px",
                        color: "lime",
                        background: "rgba(0, 255, 0, 0.8)",
                        padding: "25px",
                        borderRadius: "50%",
                        animation: "scaleUp 0.6s ease-in-out"
                    }} />
                    <p style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "bold",
                        animation: "textFade 1s ease-in-out"
                    }}>Success!</p>
                </>
            ) : (
                <>
                    <FaTimesCircle style={{
                        fontSize: "80px",
                        color: "red",
                        background: "rgba(255, 0, 0, 0.8)",
                        padding: "25px",
                        borderRadius: "50%",
                        animation: "shake 0.6s ease-in-out"
                    }} />
                    <p style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: "bold",
                        animation: "textFade 1s ease-in-out"
                    }}>Failed!</p>
                </>
            )}
        </div>,
        "status-overlay",
        true,
        2000
    );
}

function injectCSS() {
    if (!document.getElementById("dynamic-styles")) {
        const style = document.createElement("style");
        style.id = "dynamic-styles";
        style.innerHTML = `
            @keyframes spin-animation {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }

            @keyframes fadeIn {
                from { opacity: 0; transform: scale(0.9); }
                to { opacity: 1; transform: scale(1); }
            }

            @keyframes textFade {
                from { opacity: 0.6; }
                to { opacity: 1; }
            }

            /* ✅ Bounce Animation for Success */
            @keyframes scaleUp {
                0% { transform: scale(0.8); opacity: 0.5; }
                50% { transform: scale(1.2); opacity: 1; }
                100% { transform: scale(1); opacity: 1; }
            }

            /* ❌ Shake Animation for Failure */
            @keyframes shake {
                0% { transform: translateX(0); }
                25% { transform: translateX(-10px); }
                50% { transform: translateX(10px); }
                75% { transform: translateX(-10px); }
                100% { transform: translateX(0); }
            }
        `;
        document.head.appendChild(style);
    }
}

// Save (or update) data using POST with FormData
export async function saveData(id, data) {

    
        // Call the function to inject CSS
        injectCSS();

    try {
        showLoading(); // Show loading animation

        const formData = new FormData();
        formData.append('_id', id);
        formData.append('extras', data);

        const response = await fetch(ENDPOINTS.SAVE_DATA(id), {
            method: 'POST',
            body: formData,
            headers: {
                'update': 'true',
            }
        });

        removeLoading(); // Hide loading

        if (!response.ok) {
            throw new Error(`Server error: ${response.statusText}`);
        }

        const result = await response.json();
        console.log('✅ Data saved successfully:', result);

        showStatusIcon("success"); // Show success icon
        return result;
    } catch (error) {
        console.error('❌ Failed to save data:', error);
        removeLoading();
        showStatusIcon("failure"); // Show failure icon
        throw error;
    }
}
