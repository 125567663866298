import { createNodeBuilderDialog } from './inputDialog/dialog'; // Adjust the path based on your project structure
import { createXButtonBuilder } from './inputDialog/x_button'; // Adjust the path based on your project structure
import { createInputName } from './inputDialog/nameInput'; // Adjust the path based on your project structure
import { createValueInput } from './inputDialog/nodeValueInput'; // Adjust the path based on your project structure
import { createInputListContainer } from './inputDialog/inputsContainer'; // Adjust the path based on your project structure
import { createOutputListContainer } from './inputDialog/outputContainer'; // Adjust the path based on your project structure
import { createNodeTypeSelectorContainer, createNodeCodeExtensionSelectorContainer } from './inputDialog/nodeTypeSelector'; // Adjust the path based on your project structure
import { createConfirmButtonContainer } from './inputDialog/confirmButton'; // Adjust the path based on your project structure
import { createTabSelectorContainer } from './inputDialog/tabselector'; // Adjust the path based on your project structure
import {NodeType, CodeTypeExtensionCode, CodeTypeExtension, CodeTypeExtensionStyle, NodeTypeCODE, NodeTypeVALUE } from './options'; // Adjust the path based on your project structure



/**
 * This input dialog is for the
 * 1- creation of nodes
 * 2- modification of nodes
 *
 * 
 * inputs are the incomming inputs
 * intputList are the new created elements
 * 
 * @param {*} onConfirm  onConfirm callback
 * @param {*} defaults default parameters sent to this method
 */
export function createInputDialog(onConfirm, defaults = {}) {
    console.log("createInputDialog");
    const { nodeName = '', inputs = [], outputs = [], selectedType = "" , selectedNodeTypeExtension = "" , valueInputV = "" } = defaults;
    const inputList = [];
    const outputList = [];
    let nodeTypeE = "";
    let nodeTypeContainer = "";
    let nodeTypeSelector = "";
    let nodeTypeExtensionE = "";

    //Creates the dialog
    const dialog = createNodeBuilderDialog();

    //The X button that closes the dialog
    const closeButton = createXButtonBuilder();
    closeButton.onclick = () => document.body.removeChild(dialog);
    dialog.appendChild(closeButton);

    // Node name input
    const [nameContainer, nameInput] = createInputName(nodeName);
    const [valueContainer, valueInput] = createValueInput(valueInputV);
    valueContainer.style.display = (selectedType==="CODE" || selectedType==="")?"none":"block";
    dialog.appendChild(nameContainer);
    dialog.appendChild(valueContainer);

    //Add inputs sections 
    const inputsContainer = createInputListContainer(inputs, inputList);

    // Create a container for output fields
    const outputsContainer = createOutputListContainer(outputs, outputList);

    // Tabs paneel
    const tabContainer = createTabSelectorContainer(inputsContainer,outputsContainer);
    tabContainer.style.display = selectedType==="CODE"?"block":"none";
    dialog.appendChild(tabContainer);


    // CODE EXTENSION SELECTOR
    function myCallbackTabExtension(nodeTypeExtension) {
        console.log(nodeTypeExtension);
        nodeTypeExtensionE = nodeTypeExtension
        if(
            (nodeTypeExtension === "HTML" || nodeTypeExtension === "CSS") && nodeTypeE === "CODE" || nodeTypeE === "VALUE"
            
        ){
            tabContainer.style.display = "none";
        }else{

            if(nodeTypeE === ""){
                tabContainer.style.display = "none";
            }else{
                tabContainer.style.display = "block";
            }
        }


        if(nodeTypeExtension === "HTML" || nodeTypeExtension === "CSS"){
            nodeTypeSelector.innerHTML = "";
            Object.values(NodeTypeCODE).forEach(nodeType => {
                const option = document.createElement('option');
                option.value = nodeType;
                option.textContent = nodeType;
                nodeTypeSelector.appendChild(option);
            });
        }else{
            nodeTypeSelector.innerHTML = "";
            Object.values(NodeType).forEach(nodeType => {
                const option = document.createElement('option');
                option.value = nodeType;
                option.textContent = nodeType;
                nodeTypeSelector.appendChild(option);
            });
            nodeTypeSelector.value = nodeTypeE;
        }
    }

    const [nodeTypeContainerExtension, nodeTypeSelectorExtension] = createNodeCodeExtensionSelectorContainer(selectedNodeTypeExtension, myCallbackTabExtension);
    nodeTypeContainerExtension.style.display = "block";
    dialog.appendChild(nodeTypeContainerExtension);


    // NODE TYPE SELECTOR 
    function myCallbackTab(nodeType) {
        
        
        nodeTypeE = nodeType

        //This section hides value for html and css because no need to code only for html and css
        if(nodeType === "VALUE"){
            nodeTypeSelectorExtension.innerHTML = "";
            Object.values(CodeTypeExtensionCode).forEach(nodeType => {
            const option = document.createElement('option');
            option.value = nodeType;
            option.textContent = nodeType;
            nodeTypeSelectorExtension.appendChild(option);
         });
        }else{
            nodeTypeSelectorExtension.innerHTML = "";
            Object.values(CodeTypeExtension).forEach(nodeType => {
            const option = document.createElement('option');
            option.value = nodeType;
            option.textContent = nodeType;
            nodeTypeSelectorExtension.appendChild(option);
        });
    }
        
        //This section hides the tab selector
        if(nodeType === "VALUE"){
            tabContainer.style.display = "none";
            valueContainer.style.display = "block";
        }else{
            valueContainer.style.display = "none";
            if(nodeTypeExtensionE === "HTML" || nodeTypeExtensionE === "CSS"){
                tabContainer.style.display = "none";
            }else{
                if(nodeTypeExtensionE === ""){
                    tabContainer.style.display = "none";
                }else{
                    tabContainer.style.display = "block";
                }
            }
        }

        

        
    }
     [nodeTypeContainer, nodeTypeSelector] = createNodeTypeSelectorContainer(selectedType, myCallbackTab);
    nodeTypeContainer.style.display = selectedType===""?"block":"none";
    dialog.appendChild(nodeTypeContainer);



    // Confirm button
    const confirmButton = createConfirmButtonContainer(inputList,outputList,nameInput,nodeTypeSelector,onConfirm,dialog,valueInput,nodeTypeSelectorExtension);

    dialog.appendChild(confirmButton);
    document.body.appendChild(dialog);
}
