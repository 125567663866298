import {showEditorPopup} from './editor/editorContainer'; // Import MonacoEditor component
// Function to show the Monaco Editor in a modal dialog





export const showPopup = (editNode, id, nodeType, nodeTypeExention, nodename) => {
  // Create the popup div
  const popupDiv = document.createElement('div');
  popupDiv.className = 'popup-container';

  // Set inner HTML conditionally
  popupDiv.innerHTML = `
    <div class="popup-dialog">
      <div class="popup-header">Options</div>
      <ul class="popup-options">
        <li id="edit-node">Edit Node</li>
        ${nodeType === "CODE" ? '<li id="edit-function-code">Edit Function Code</li>' : ''}
      </ul>
    </div>
  `;

  // Append the popup to the body
  document.body.appendChild(popupDiv);

  // Add event listeners to the options
  document.getElementById('edit-node').addEventListener('click', () => {
      editNode();
      closePopup();
  });

  if (nodeType === "CODE") {
      document.getElementById('edit-function-code').addEventListener('click', () => {

            // Define the mapping for file extensions and editor types
                const extensionMap = {
                  "JAVASCRIPT": { ext: ".js", editorType: "javascript" },
                  "HTML": { ext: ".html", editorType: "html" },
                  "CSS": { ext: ".css", editorType: "css" },
                  "PYTHON": { ext: ".py", editorType: "python" }
              };

              // Convert nodeTypeExention to uppercase to ensure matching
              const fileInfo = extensionMap[nodeTypeExention.toUpperCase()];

              if (fileInfo) {
                  // Append the corresponding file extension
                  const updatedNodeName = nodename + fileInfo.ext;

                  // Call showEditorPopup with the correct editor type
                  showEditorPopup(fileInfo.editorType, id, updatedNodeName);
              } else {
                  console.error("Unsupported node type:", nodeTypeExention);
              }

          closePopup();
      });
  }

  // Close popup when clicking outside
  const handleClickOutside = (event) => {
      if (!popupDiv.contains(event.target)) {
          closePopup();
      }
  };

  // Function to remove the popup
  const closePopup = () => {
      popupDiv.remove();
      document.removeEventListener('mousedown', handleClickOutside);
  };

  // Add listener to detect clicks outside the popup
  document.addEventListener('mousedown', handleClickOutside);

  // Dynamically add CSS styles
  const style = document.createElement('style');
  style.innerHTML = `
    .popup-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      width: 250px;
      z-index: 1000;
    }

    .popup-dialog {
      display: flex;
      flex-direction: column;
    }

    .popup-header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }

    .popup-options {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .popup-options li {
      padding: 10px;
      background-color: #f0f0f0;
      margin: 5px 0;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      transition: background-color 0.2s;
    }

    .popup-options li:hover {
      background-color: #e0e0e0;
    }
  `;
  // Append the style to the head of the document
  document.head.appendChild(style);
};



