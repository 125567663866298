

/**
 *  THis is the Input of the name
 * 
 *  Reference of the nodeName 
 * @returns 
 */
export function createInputName(nodeName) {

  
    // Close button
    const nameContainer = document.createElement('div');
    nameContainer.style.marginBottom = '15px';

    const nameLabel = document.createElement('label');
    nameLabel.textContent = 'Node Name:';
    nameLabel.style.fontWeight = 'bold';
    nameContainer.appendChild(nameLabel);

    const nameInput = document.createElement('input');
    nameInput.type = 'text';
    nameInput.placeholder = 'Enter node name';
    nameInput.style.marginTop = '5px';
    nameInput.style.width = '100%';
    nameInput.style.padding = '8px';
    nameInput.style.border = '1px solid #ccc';
    nameInput.style.borderRadius = '4px';
    nameInput.value = nodeName;
    nameContainer.appendChild(nameInput);

    return [nameContainer, nameInput];

}