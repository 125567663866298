const GlobalStore = {
    data: "", // Default value
  
    setData(value) {
      this.data = value;
    },
  
    getData() {
      return this.data;
    },
  };
  
  export default GlobalStore;
  