import { OutputTypes } from '../options'; // Adjust the path based on your project structure

/**
 * THis creates the ourputs container to add inputs
 * 
 * original outputs array 
 */

export function createOutputListContainer(outputs, outputList) {
    const outputsContainer = document.createElement('div');
    outputsContainer.style.marginBottom = '15px';

    const outputsLabel = document.createElement('label');
    outputsLabel.textContent = 'Outputs:';
    outputsLabel.style.fontWeight = 'bold';
    outputsContainer.appendChild(outputsLabel);

    // Function to add output fields with a remove button
    const addOutputField = (container, list, defaultOutput = {}) => {
          const fieldContainer = document.createElement('div');
        fieldContainer.style.display = 'flex';
        fieldContainer.style.alignItems = 'center';
        fieldContainer.style.flexWrap = 'wrap';
        fieldContainer.style.gap = '12px';
        fieldContainer.style.marginTop = '10px';
        fieldContainer.style.width = '100%';
        fieldContainer.style.maxHeight = '250px'; // Limits height
        fieldContainer.style.overflowY = 'auto'; // Enables vertical scrolling when needed
        fieldContainer.style.paddingTop = '10px'; // Adds inner spacing
        fieldContainer.style.paddingBottom = '10px'; // Adds inner spacing
        fieldContainer.style.paddingLeft = '5px'; // Adds inner spacing
        fieldContainer.style.border = '1px solid #ddd'; // Light border for better definition
        fieldContainer.style.borderRadius = '6px'; // Rounded edges
        fieldContainer.style.background = '#f9f9f9'; // Light background for better visibility
        fieldContainer.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.1)'; // Subtle shadow for depth

        const outputID = document.createElement('input');
        outputID.type = 'text';
        outputID.placeholder = 'Output ID';
        outputID.style.flex = '1';
        outputID.style.padding = '8px';
        outputID.style.border = '1px solid #ccc';
        outputID.style.borderRadius = '4px';
        outputID.value = defaultOutput.id || '';
        fieldContainer.appendChild(outputID);

        const comparisonType = document.createElement('select');
        comparisonType.style.flex = '1';
        comparisonType.style.marginLeft = '10px';
        comparisonType.style.padding = '8px';
        comparisonType.style.border = '1px solid #ccc';
        comparisonType.style.borderRadius = '4px';

        // Populate the dropdown and set the default selection to index 0
        Object.values(OutputTypes).forEach((optionText, index) => {
            const option = document.createElement('option');
            option.value = optionText;
            option.textContent = optionText;
            if (index === 0) option.selected = true; // Ensure the first option is selected by default
            comparisonType.appendChild(option);
        });

        // Ensure default selection is at index 0 if not explicitly set
        if (defaultOutput.comparisonType && Object.values(OutputTypes).includes(defaultOutput.comparisonType)) {
            comparisonType.value = defaultOutput.comparisonType;
        } else {
            comparisonType.selectedIndex = 0; // Ensure first option is selected
        }

        fieldContainer.appendChild(comparisonType);

        const removeButton = document.createElement('button');
        removeButton.textContent = '🗑️';
        removeButton.style.marginLeft = '5px';
        removeButton.style.backgroundColor = 'transparent';
        removeButton.style.border = 'none';
        removeButton.style.cursor = 'pointer';
        removeButton.onclick = () => {
            container.removeChild(fieldContainer);
            const index = list.findIndex(item => item.outputID === outputID);
            if (index > -1) list.splice(index, 1);
        };
        fieldContainer.appendChild(removeButton);

        container.appendChild(fieldContainer);
        list.push({ outputID, comparisonType });
    };

    // Add default output fields if provided
    outputs.forEach(output => addOutputField(outputsContainer, outputList, output));

    // Add Output button
    const addOutputButton = document.createElement('button');
    addOutputButton.textContent = 'Add Output';
    addOutputButton.style.marginTop = '10px';
    addOutputButton.style.padding = '8px';
    addOutputButton.style.width = '100%';
    addOutputButton.style.backgroundColor = '#28a745';
    addOutputButton.style.color = 'white';
    addOutputButton.style.border = 'none';
    addOutputButton.style.borderRadius = '5px';
    addOutputButton.style.cursor = 'pointer';
    addOutputButton.onclick = () => addOutputField(outputsContainer, outputList);
    outputsContainer.appendChild(addOutputButton);
    
    return outputsContainer;
}
