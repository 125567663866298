

/**
 * This is the X button on the top right corner that closes the dialog
 */
export function createXButtonBuilder() {

  
// Close button
const closeButton = document.createElement('button');
closeButton.textContent = '✖'; // Unicode for better design than 'X'

// Style improvements
closeButton.style.position = 'absolute';
closeButton.style.top = '10px';
closeButton.style.right = '10px';
closeButton.style.width = '32px';
closeButton.style.height = '32px';
closeButton.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'; // Semi-transparent
closeButton.style.border = 'none';
closeButton.style.borderRadius = '50%'; // Circular shape
closeButton.style.color = '#fff';
closeButton.style.fontSize = '18px';
closeButton.style.fontWeight = 'bold';
closeButton.style.cursor = 'pointer';
closeButton.style.display = 'flex';
closeButton.style.alignItems = 'center';
closeButton.style.justifyContent = 'center';
closeButton.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.3)';
closeButton.style.transition = 'all 0.3s ease';

// Hover effect
closeButton.addEventListener('mouseenter', () => {
    closeButton.style.backgroundColor = 'rgba(255, 0, 0, 0.8)'; // Red hover effect
});

// Leave effect
closeButton.addEventListener('mouseleave', () => {
    closeButton.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'; // Back to original
});

// Click effect
closeButton.addEventListener('mousedown', () => {
    closeButton.style.transform = 'scale(0.9)';
});
closeButton.addEventListener('mouseup', () => {
    closeButton.style.transform = 'scale(1)';
});

return closeButton;


}