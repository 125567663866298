import globalStoreC from "../../architecture/globalStore"; // Import the context hook
import React, { useState, useEffect } from "react";
import { Editor } from "@monaco-editor/react";
import PropTypes from "prop-types"; // Import PropTypes
import { languages } from "monaco-editor";
import { COMPOSER_SERVER } from '../../configuration/configuration';

const MyMonacoEditor = ({ id, intitialValue, language, foldername, filename }) => {
  const [value, setValue] = useState(intitialValue);

  // Load initial value based on ID from the global store
  useEffect(() => {
    if (value) {
      setValue(value); // Load existing data or sample code
    } else {
      setValue(getSampleCode(language)); // Default sample code if no data exists
    }
  }, [id, language]);

  // Sample code for different languages
  const getSampleCode = (lang) => {
    switch (lang) {
      case "python":
        return '# Sample Python Code\nprint("Hello, World!")';
      case "html":
        return "<!-- Sample HTML Code -->\n<h1>Hello, World!</h1>";
      case "css":
        return "/* Sample CSS */\nbody { background-color: blue; }";
      default:
        return '// Sample JavaScript Code\nconst greet = (name) => {\n  console.log("Hello " + name);\n};\n\ngreet("World");';
    }
  };


  // Handle file upload
  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileContent = e.target.result;
      setValue(fileContent); // Set the content in the editor
    };
    reader.readAsText(file);
  };

  // Handle content download
  const handleDownload = () => {
    const blob = new Blob([value], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `code.${getFileExtension(language)}`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Get file extension based on language
  const getFileExtension = (lang) => {
    switch (lang) {
      case "python":
        return "py";
      case "html":
        return "html";
      case "css":
        return "css";
      default:
        return "js";
    }
  };

  // Handle save (update global store)
  const handleSave = async () => {
    //globalStoreC.updateDataById(id, { data: value, config: `Language: ${language}` });
    //console.log("Data saved:", globalStoreC.dataArray);


        const url = COMPOSER_SERVER+ `compile/update?foldername=${foldername}&filename=${filename}`;

        fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "text/plain"
            },
            body: value
        })
        .then(response => response.text())
        .then(data => {
            alert("Success:", data);
        })
        .catch(error => {
          alert("Error:", error);
        });
      
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        background: "#1e1e1e", // Matches Monaco Dark Theme
      }}
    >
      {/* Header with language selection */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          height:"50px",
          width:"50px",
          background: "#222",
          color: "white",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
     <label style={{ display: "inline-block", whiteSpace: "nowrap" }}>
        File:    {foldername} / {filename}
    </label>


        {/* <label>Choose Language:</label>
        <select
          value={language}
          onChange={handleLanguageChange}
          style={{
            padding: "6px 10px",
            borderRadius: "4px",
            background: "#333",
            color: "white",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <option value="javascript">JavaScript</option>
          <option value="python">Python</option>
          <option value="html">HTML</option>
          <option value="css">CSS</option>
        </select> */}

    

      </div>

      {/* Monaco Editor */}
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Editor
          height="100%"
          width="100%"
          language={language}
          value={value}
          theme="vs-dark"
          onChange={(newValue) => setValue(newValue)}
          options={{
            automaticLayout: true, // Ensures responsiveness
          }}
        />
      </div>

      {/* Controls for file upload, download, and save */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          background: "#222",
          borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <input
          type="file"
          accept=".js,.py,.html,.css"
          onChange={handleUpload}
          style={{
            color: "white",
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            padding: "8px 15px",
            background: "#007bff",
            border: "none",
            color: "white",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background 0.2s",
          }}
          onMouseEnter={(e) => (e.target.style.background = "#0056b3")}
          onMouseLeave={(e) => (e.target.style.background = "#007bff")}
        >
          Download
        </button>
        <button
          onClick={handleSave}
          style={{
            padding: "8px 15px",
            background: "#28a745",
            border: "none",
            color: "white",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "background 0.2s",
          }}
          onMouseEnter={(e) => (e.target.style.background = "#218838")}
          onMouseLeave={(e) => (e.target.style.background = "#28a745")}
        >
          Save
        </button>
      </div>
    </div>
  );
};

// Define the expected type for the `id` prop
MyMonacoEditor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  intitialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
  language: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
  foldername: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
  filename: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
};

export default MyMonacoEditor;
