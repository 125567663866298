import { NodeType,CodeTypeExtension } from '../options'; // Adjust the path based on your project structure


/**
 * The selector of the node type
 * 
 * original outputs array 
 */

export function createNodeTypeSelectorContainer(selectedType, myCallbackTab) {

    const nodeTypeContainer = document.createElement('div');
    nodeTypeContainer.style.marginBottom = '15px';
    nodeTypeContainer.style.marginTop = '10px';

    const nodeTypeLabel = document.createElement('label');
    nodeTypeLabel.textContent = 'Node Type:';
    nodeTypeLabel.style.fontWeight = 'bold';
    nodeTypeLabel.style.marginTop = '5px';
    nodeTypeContainer.appendChild(nodeTypeLabel);

    const nodeTypeSelector = document.createElement('select');
    nodeTypeSelector.style.marginTop = '5px';
    nodeTypeSelector.style.width = '100%';
    nodeTypeSelector.style.padding = '8px';
    nodeTypeSelector.style.border = '1px solid #ccc';
    nodeTypeSelector.style.borderRadius = '4px';
    Object.values(NodeType).forEach(nodeType => {
        const option = document.createElement('option');
        option.value = nodeType;
        option.textContent = nodeType;
        nodeTypeSelector.appendChild(option);
    });

    nodeTypeSelector.addEventListener('change', (event) => {
        myCallbackTab(event.target.value);
    });

    nodeTypeSelector.value = selectedType;
    nodeTypeContainer.appendChild(nodeTypeSelector);

    return [ nodeTypeContainer , nodeTypeSelector];
}

export function createNodeCodeExtensionSelectorContainer(selectedTypeExtension, myCallbackTabExtension) {

    const nodeTypeContainer = document.createElement('div');
    nodeTypeContainer.style.marginBottom = '15px';
    nodeTypeContainer.style.marginTop = '10px';

    const nodeTypeLabel = document.createElement('label');
    nodeTypeLabel.textContent = 'Node Type Extension:';
    nodeTypeLabel.style.fontWeight = 'bold';
    nodeTypeLabel.style.marginTop = '5px';
    nodeTypeContainer.appendChild(nodeTypeLabel);

    const nodeTypeSelector = document.createElement('select');
    nodeTypeSelector.style.marginTop = '5px';
    nodeTypeSelector.style.width = '100%';
    nodeTypeSelector.style.padding = '8px';
    nodeTypeSelector.style.border = '1px solid #ccc';
    nodeTypeSelector.style.borderRadius = '4px';
    Object.values(CodeTypeExtension).forEach(nodeType => {
        const option = document.createElement('option');
        option.value = nodeType;
        option.textContent = nodeType;
        nodeTypeSelector.appendChild(option);
    });

    nodeTypeSelector.addEventListener('change', (event) => {
        myCallbackTabExtension(event.target.value);
    });

    nodeTypeSelector.value = selectedTypeExtension;
    nodeTypeContainer.appendChild(nodeTypeSelector);

    return [ nodeTypeContainer , nodeTypeSelector];
}