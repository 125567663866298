import { NodeType } from '../options'; // Adjust the path based on your project structure


/**
 * The selector of the node type
 * 
 * original outputs array 
 */

export function createConfirmButtonContainer(
    inputList,  //intpus
    outputList,  //outpus
    nameInput,   // name
    nodeTypeSelector, //type code value
    onConfirm, //confirmo
    dialog,  // dialog
    valueInput, // value input
    nodeTypeSelectorExtensiong // javascrit , html ..
) {

    const confirmButton = document.createElement('button');
    confirmButton.textContent = '✔ Confirm'; // Unicode checkmark for better UI

    // Style improvements
        confirmButton.style.marginTop = '20px';
        confirmButton.style.padding = '12px 16px'; // More padding for better UX
        confirmButton.style.width = '100%';
        confirmButton.style.backgroundColor = '#28a745';
        confirmButton.style.color = 'white';
        confirmButton.style.border = 'none';
        confirmButton.style.borderRadius = '8px'; // More rounded edges
        confirmButton.style.cursor = 'pointer';
        confirmButton.style.fontSize = '16px';
        confirmButton.style.fontWeight = 'bold';
        confirmButton.style.textAlign = 'center';
        confirmButton.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
        confirmButton.style.transition = 'all 0.3s ease';
    confirmButton.onclick = () => {
        const nodeName = nameInput.value.trim();
        const inputs = inputList.map(inputField => ({
            id: inputField.id.value.trim(),
            value: inputField.value.value.trim()
        }));
        const outputs = outputList.map(output => ({
            id: output.outputID.value.trim(),
            comparisonType: output.comparisonType.value
        }));
        const selectedNodeType = nodeTypeSelector.value;
        const selectedNodeTypeExtension = nodeTypeSelectorExtensiong.value;

        if (!nodeName) {
            alert('Node name cannot be empty.');
            return;
        }

        if (inputs.some(val => val.id.includes(' ')) || outputs.some(output => output.id.includes(' '))) {
            alert('IDs cannot contain spaces.');
            return;
        }

        onConfirm(nodeName, inputs, outputs, selectedNodeType, valueInput.value, selectedNodeTypeExtension);
        document.body.removeChild(dialog);
    };


    // Hover effect
    confirmButton.addEventListener('mouseenter', () => {
        confirmButton.style.backgroundColor = '#218838'; // Darker green on hover
        confirmButton.style.transform = 'scale(1.05)';
    });

    // Leave effect
    confirmButton.addEventListener('mouseleave', () => {
        confirmButton.style.backgroundColor = '#28a745';
        confirmButton.style.transform = 'scale(1)';
    });

    // Click effect
    confirmButton.addEventListener('mousedown', () => {
        confirmButton.style.transform = 'scale(0.95)';
    });
    confirmButton.addEventListener('mouseup', () => {
        confirmButton.style.transform = 'scale(1.05)';
    });

    return confirmButton;
}