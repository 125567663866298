// LOS nodos son funciones

/**
 * 
 *  Una funcion tiene parametros 
 *  Una funcion tiene salidas  
 * 
 */

/**
 *  Un valor solo puede tener una entrada y una salida
 *  Se puede coloar un parametro como entrada de una funcion 
 *  Puede tener un control que sirva como entrada para una funcion
 */

// options.js --- COMPARATOR EXIT
export const OptionTypes = Object.freeze({
    BOOLEAN: 'Boolean',
    EQUALS: '=',
    GREATER_THAN: '>',
    EQUAL_OR_GREATER_THAN: '=>',
    EQUAL_OR_LOWER_THAN: '<=',
    LOWER: '<',
     SETTER: 'SETTER',
});


// options.js --- OUTPUT
export const OutputTypes = Object.freeze({
   SETTER: 'SETTER',
});


// options.js --- NODE-TYPE
export const NodeType = Object.freeze({
    CODE: 'CODE',
    INPUT: 'VALUE',
});

export const NodeTypeVALUE = Object.freeze({
    INPUT: 'VALUE',
});

export const NodeTypeCODE = Object.freeze({
    CODE: 'CODE',
});


// options.js --- NODE-TYPE
export const CodeTypeExtension = Object.freeze({
    JAVASCRIPT: 'JAVASCRIPT',
    PYTHON: 'PYTHON',
    HTML: 'HTML',
    CSS: 'CSS',
});


export const CodeTypeExtensionCode = Object.freeze({
    JAVASCRIPT: 'JAVASCRIPT',
    PYTHON: 'PYTHON',
});


export const CodeTypeExtensionStyle = Object.freeze({
    HTML: 'HTML',
    CSS: 'CSS',
});


