
/**
 * THis creates the inputs container to add inputs
 * 
s */

export function createTabSelectorContainer(inputsContainer, outputsContainer) {

        // Create a tab container
        const tabContainer = document.createElement("div");
        tabContainer.innerHTML = `
        <div style="display: flex; border-bottom: 3px solid #007bff; background: #f8f9fa;">
            <button class="tab-button active" data-tab="inputs"
                style="flex: 1; padding: 12px 18px; border: none; background: #007bff; color: white; 
                       cursor: pointer; font-size: 16px; font-weight: bold; transition: 0.3s ease; 
                       border-top-left-radius: 6px; border-top-right-radius: 6px;">
                Inputs
            </button>
            <button class="tab-button" data-tab="outputs"
                style="flex: 1; padding: 12px 18px; border: none; background: #e0e0e0; color: #333; 
                       cursor: pointer; font-size: 16px; transition: 0.3s ease; 
                       border-top-left-radius: 6px; border-top-right-radius: 6px;">
                Outputs
            </button>
        </div>
        <div class="tab-content" style="border: 1px solid #ddd; border-radius: 6px; padding: 20px; background: white;">
            <div id="inputs-tab" class="tab-panel active" style="display: block;"></div>
            <div id="outputs-tab" class="tab-panel" style="display: none;"></div>
        </div>
    `;


        // Select the tabs and panels
        const inputTab = tabContainer.querySelector("[data-tab='inputs']");
        const outputTab = tabContainer.querySelector("[data-tab='outputs']");
        const inputPanel = tabContainer.querySelector("#inputs-tab");
        const outputPanel = tabContainer.querySelector("#outputs-tab");

        // Create input and output containers

        // Append to respective panels
        inputPanel.appendChild(inputsContainer);
        outputPanel.appendChild(outputsContainer);

        // Add event listeners for tab switching
        const tabButtons = tabContainer.querySelectorAll(".tab-button");
        const tabPanels = tabContainer.querySelectorAll(".tab-panel");

        tabButtons.forEach(button => {
            button.addEventListener("click", () => {
                // Remove active class from all buttons
                tabButtons.forEach(btn => {
                    btn.style.background = "#f1f1f1";
                    btn.style.fontWeight = "normal";
                });

                // Remove active panel
                tabPanels.forEach(panel => {
                    panel.style.display = "none";
                });

                // Activate selected tab
                button.style.background = "#ddd";
                button.style.fontWeight = "bold";

                // Show the corresponding panel
                document.getElementById(`${button.dataset.tab}-tab`).style.display = "block";
            });
        });


        //Style

        tabButtons.forEach(button => {
            button.addEventListener("click", () => {
                // Reset styles for all buttons
                tabButtons.forEach(btn => {
                    btn.style.background = "#e0e0e0";
                    btn.style.color = "#333";
                    btn.style.fontWeight = "normal";
                });
        
                // Reset panels
                tabPanels.forEach(panel => panel.style.display = "none");
        
                // Activate selected tab
                button.style.background = "#007bff";
                button.style.color = "white";
                button.style.fontWeight = "bold";
        
                // Show the corresponding panel
                document.getElementById(`${button.dataset.tab}-tab`).style.display = "block";
            });
        
       
        });

        return tabContainer;

}
