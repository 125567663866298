// configuration.js

  /**
   * THis sometimes may guive cors problems because azure functions on cloud must be called only from 
   * https://composer.nzsapps.com/ otherwise will cause a failded to fetch , this is for security reasons
   * 
   * local must be called from local 
   * prod must be called only from prod
   */

//export const API_BASE_URL =  "http://localhost:7071/";
//export const COMPOSER_SERVER =  "http://localhost:1070/";
export const COMPOSER_SERVER =  "https://composerserver.nzsapps.com/"; 
export const API_BASE_URL = "https://nzsappsf.azurewebsites.net/";

// COMPOSITIONS
export const ENDPOINTS = {
    FETCH_DATA: (id) => `${API_BASE_URL}api/CompositionModules/`,
    SAVE_DATA: (id) => `${API_BASE_URL}api/CompositionModules/`,
    // Add more endpoints as needed
};
