
/**
 * THis creates the inputs container to add inputs
 * 
 * origina inputs array 
 */

export function createInputListContainer(inputs, inputList) {
    // Create a container for input fields
    const inputsContainer = document.createElement('div');
    inputsContainer.style.marginBottom = '15px';
    inputsContainer.style.width = '100%';

    // Inputs Label
    const inputsLabel = document.createElement('label');
    inputsLabel.textContent = 'Inputs:';
    inputsLabel.style.fontWeight = 'bold';
    inputsLabel.style.display = 'block';
    inputsLabel.style.marginBottom = '5px';
    inputsContainer.appendChild(inputsLabel);

    // Function to add input fields (ID & Value) with a remove button
    const addInputField = (container, list, defaultID = '', defaultValue = '') => {
        const fieldContainer = document.createElement('div');
        fieldContainer.style.display = 'flex';
        fieldContainer.style.alignItems = 'center';
        fieldContainer.style.flexWrap = 'wrap';
        fieldContainer.style.gap = '12px';
        fieldContainer.style.marginTop = '10px';
        fieldContainer.style.width = '100%';
        fieldContainer.style.maxHeight = '250px'; // Limits height
        fieldContainer.style.overflowY = 'auto'; // Enables vertical scrolling when needed
        fieldContainer.style.padding = '10px'; // Adds inner spacing
        fieldContainer.style.border = '1px solid #ddd'; // Light border for better definition
        fieldContainer.style.borderRadius = '6px'; // Rounded edges
        fieldContainer.style.background = '#f9f9f9'; // Light background for better visibility
        fieldContainer.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.1)'; // Subtle shadow for depth
        

        // Input ID
        const inputID = document.createElement('input');
        inputID.type = 'text';
        inputID.placeholder = 'Input ID';
        inputID.style.flex = '1';
        inputID.style.padding = '10px';
        inputID.style.border = '1px solid #ccc';
        inputID.style.borderRadius = '6px';
        inputID.style.minWidth = '120px';
        inputID.value = defaultID;

        // Input Value
        const inputValue = document.createElement('input');
        inputValue.type = 'text';
        inputValue.placeholder = 'Value';
        inputValue.style.flex = '1';
        inputValue.style.padding = '10px';
        inputValue.style.border = '1px solid #ccc';
        inputValue.style.borderRadius = '6px';
        inputValue.style.minWidth = '120px';
        inputValue.value = defaultValue;

        // Remove Button
        const removeButton = document.createElement('button');
        removeButton.textContent = '🗑️';
        removeButton.style.backgroundColor = 'transparent';
        removeButton.style.border = 'none';
        removeButton.style.cursor = 'pointer';
        removeButton.style.fontSize = '18px';
        removeButton.style.color = '#ff4d4d';
        removeButton.style.transition = 'color 0.2s ease';

        // Remove Button Hover Effect
        removeButton.addEventListener('mouseenter', () => {
            removeButton.style.color = '#ff0000';
        });

        removeButton.addEventListener('mouseleave', () => {
            removeButton.style.color = '#ff4d4d';
        });

        removeButton.onclick = () => {
            container.removeChild(fieldContainer);
            const index = list.findIndex(item => item.id === inputID);
            if (index > -1) list.splice(index, 1);
        };

        // Append elements
        fieldContainer.appendChild(inputID);
        fieldContainer.appendChild(inputValue);
        fieldContainer.appendChild(removeButton);
        container.appendChild(fieldContainer);
        list.push({ id: inputID, value: inputValue });
    };

    // Add default input fields if provided
    inputs.forEach(input => addInputField(inputsContainer, inputList, input.id, input.value));

    // Add Input Button
    const addInputButton = document.createElement('button');
    addInputButton.textContent = '➕ Add Input';
    addInputButton.style.marginTop = '10px';
    addInputButton.style.padding = '10px';
    addInputButton.style.width = '100%';
    addInputButton.style.backgroundColor = '#007BFF';
    addInputButton.style.color = 'white';
    addInputButton.style.border = 'none';
    addInputButton.style.borderRadius = '6px';
    addInputButton.style.cursor = 'pointer';
    addInputButton.style.fontSize = '14px';
    addInputButton.style.transition = 'background-color 0.2s ease';

    // Add Button Hover Effect
    addInputButton.addEventListener('mouseenter', () => {
        addInputButton.style.backgroundColor = '#0056b3';
    });

    addInputButton.addEventListener('mouseleave', () => {
        addInputButton.style.backgroundColor = '#007BFF';
    });

    addInputButton.onclick = () => addInputField(inputsContainer, inputList);
    inputsContainer.appendChild(addInputButton);

    return inputsContainer;
}
