

/**
 * Creates the dialog in which the options are going to be place, 
 * this dialogs creates one node.
 */
export function createNodeBuilderDialog() {
    // Create Dialog Container
    const dialog = document.createElement('div');
    dialog.style.position = 'fixed';
    dialog.style.top = '50%';
    dialog.style.left = '50%';
    dialog.style.transform = 'translate(-50%, -50%)';
    dialog.style.backgroundColor = '#fff';
    dialog.style.padding = '20px';
    dialog.style.width = '500px';
    dialog.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
    dialog.style.zIndex = '1001';
    dialog.style.borderRadius = '10px';
    dialog.style.fontFamily = 'Arial, sans-serif';
    dialog.style.overflowY = 'auto'; // Enables scrolling *only when needed*
    dialog.style.maxWidth = '90vw';
    dialog.style.maxHeight = '90vh';
    dialog.style.cursor = 'grab'; // Set cursor to indicate drag

    // Title Bar
    const titleBar = document.createElement('div');
    titleBar.style.backgroundColor = '#007bff';
    titleBar.style.color = 'white';
    titleBar.style.padding = '12px 15px';
    titleBar.style.borderTopLeftRadius = '10px';
    titleBar.style.borderTopRightRadius = '10px';
    titleBar.style.userSelect = 'none';
    titleBar.style.display = 'flex';
    titleBar.style.alignItems = 'center';
    titleBar.style.justifyContent = 'center';
    titleBar.style.fontSize = '16px';
    titleBar.style.fontWeight = 'bold';
    titleBar.style.letterSpacing = '0.5px';
    titleBar.textContent = 'Node Configuration';

    dialog.appendChild(titleBar);

    // Content Container
    const contentContainer = document.createElement('div');
    contentContainer.style.padding = '15px';
    dialog.appendChild(contentContainer);

    // Dragging Variables
    let isDragging = false;
    let startX = 0, startY = 0;
    let dialogX = 0, dialogY = 0;

    // Mouse Down - Start Dragging
    dialog.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;

        // Get the current position of the dialog
        const rect = dialog.getBoundingClientRect();
        dialogX = rect.left;
        dialogY = rect.top;

        dialog.style.cursor = 'grabbing';
        document.body.style.userSelect = 'none'; // Prevents text selection
    });

    // Mouse Move - Drag Dialog (With Boundaries)
    document.addEventListener('mousemove', (e) => {
        if (!isDragging) return;

        let newX = dialogX + (e.clientX - startX);
        let newY = dialogY + (e.clientY - startY);

        // Keep within viewport bounds
        const minX = 0;
        const minY = 0;
        const maxX = window.innerWidth - dialog.offsetWidth;
        const maxY = window.innerHeight - dialog.offsetHeight;

        newX = Math.max(minX, Math.min(newX, maxX));
        newY = Math.max(minY, Math.min(newY, maxY));

        // Apply new position
        dialog.style.left = `${newX}px`;
        dialog.style.top = `${newY}px`;
        dialog.style.transform = 'none'; // Remove centering transformation
    });

    // Mouse Up - Stop Dragging
    document.addEventListener('mouseup', () => {
        isDragging = false;
        dialog.style.cursor = 'grab';
        document.body.style.userSelect = ''; // Re-enable text selection
    });

    return dialog;
}



