import { ClassicPreset } from "rete";
import { ButtonControl, ProgressControl } from '../components/CustomControls';
import { socket } from "../sockets/sockets";
import "antd/dist/reset.css";
import "./popup";
import React from "react";
import { createInputDialog } from "../components/InputDialog"; // Importing the dialog component
import { OptionTypes } from '../components/options'; // Adjust the path based on your project structure
import { showPopup } from "./popup";

// Custom Node Class extending ClassicPreset.Node
export class CustomNode extends ClassicPreset.Node {

  constructor(name = "Node", area, inputIds = [], outputIds = [], selectedType, valueInput, selectedNodeTypeExtension) {
    super(name); // Set the custom node name

    this.inputsIds = inputIds;
    this.outputIds = outputIds;
    this.area = area; 
    this.label = name;
    this.valueInput = valueInput;
    this.selectedType = selectedType;
    this.selectedNodeTypeExtension = selectedNodeTypeExtension;

    // Initialize inputs and outputs
    this.updateIO(inputIds, outputIds);

    // Add initial controls based on selectedType
    if (selectedType === OptionTypes.EQUALS) {
      const progressControl = new ProgressControl(0);
      const inputControl = new ClassicPreset.InputControl("number", {
        initial: 0,
        change(value) {
          progressControl.percent = value;
          area.update("control", progressControl.id);
        }
      });
      
      this.addControl("input", inputControl);

    } else if (selectedType === OptionTypes.EQUAL_OR_GREATER_THAN) {
      const progressControl = new ProgressControl(0);
      this.addControl("progress", progressControl);
    }

    // Button control to open dialog and update inputs/outputs
    this.addDefaultControls();
  }

  // Method to update inputs and outputs
  updateIO(inputIds, outputIds) {
    // Clear existing inputs and outputs
    this.clearInputs();
    this.clearOutputs();

    // Add updated inputs
    inputIds.forEach(id => {
      this.addInput(id.id, new ClassicPreset.Input(socket, `Input ${id.id}`));
    });

    // Add updated outputs with comparison type
    outputIds.forEach(id => {
      this.addOutput(id.id, new ClassicPreset.Output(socket, `Output : ${id.id + " " + id.comparisonType}`));
    });

    // Trigger an update in the area to refresh the node view
    if(this.area != null && this.area != undefined ){
      this.area.update('node', this.id);
    }
  }

  clearInputs() {
    // Logic to remove all existing inputs
    Object.keys(this.inputs).forEach(key => {
      this.removeInput(key);
    });
  }

  clearOutputs() {
    // Logic to remove all existing outputs
    Object.keys(this.outputs).forEach(key => {
      this.removeOutput(key);
    });
  }

  clearControls() {
    // Logic to remove all existing controls
    Object.keys(this.controls).forEach(controlKey => {
      this.removeControl(controlKey);
    });
  }

  addControls(area) {
    // Re-add controls based on the new selected type
    if (this.selectedType === OptionTypes.EQUALS) {
      const progressControl = new ProgressControl(0);
      const inputControl = new ClassicPreset.InputControl("number", {
        initial: 0,
        change(value) {
          progressControl.percent = value;
          area.update("control", progressControl.id);
        }
      });
      this.addControl("input", inputControl);
    } else if (this.selectedType === OptionTypes.EQUAL_OR_GREATER_THAN) {
      const progressControl = new ProgressControl(0);
      this.addControl("progress", progressControl);
    }

    this.addDefaultControls();
  }


  addDefaultControls(){
        // Button control to open dialog and update inputs/outputs
        this.addControl(
          "button",
          new ButtonControl("Options", () => {
    

            showPopup(()=>{
              

               const inputsO = this.inputsIds;
                createInputDialog(async (nodeName, ginputs, outputs, selectedType,valueInput, selectedNodeTypeExtension) => {
                        // 
                          //   // Update node name
                          //   this.name = nodeName;
                  
                            // Update inputs and outputs dynamically
                            this.updateIO(ginputs, outputs);
                  
                            // Update selected type and controls
                            this.selectedType = selectedType;
                            this.valueInput = valueInput;
                            this.selectedNodeTypeExtension = selectedNodeTypeExtension;
                            this.clearControls(); // Clear existing controls
                            this.addControls(this.area); // Re-add controls based on the new type
                            this.name = nodeName;
                            this.inputsIds = ginputs;
                            this.outputIds = outputs;
                            this.selectedType = selectedType;
                            
                            
                            // Trigger an update in the area to refresh the node
                            this.area.update("node", this.id);
 
                            
                          }, 
                          {
                            nodeName: this.label,         // Current node name
                            inputs: inputsO,       // Current input IDs
                            outputs: this.outputIds,     // Current output IDs and comparison types
                            selectedType: this.selectedType, // Current selected node type 
                            valueInputV: this.valueInput,
                            selectedNodeTypeExtension: this.selectedNodeTypeExtension
                          });

            }, this.id,  this.selectedType,this.selectedNodeTypeExtension, this.label);
 
    

          })
        );
  }
}

// CustomConnection Class to handle connections between nodes
export class CustomConnection extends ClassicPreset.Connection {
  constructor(source, sourceOutput, target, targetInput ) {
    super(source, sourceOutput, target, targetInput);
    this.isMagnetic = false;
  }
}
